import React, { forwardRef } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import cn from 'classnames';
import styles from './Tabs.module.scss';

type TabsProps = TabsPrimitive.TabsProps;

type TabsContentProps = {
	'value': string;
	'className'?: string;
	'removeDefaultStyles'?: boolean;
	'role'?: 'tabpanel' | string;
	'aria-labelledby'?: string;
	'children': React.ReactNode;
};

const Content = ({ className = '', removeDefaultStyles = false, value, children }: TabsContentProps) => {
	return (
		<TabsPrimitive.Content
			tabIndex={-1}
			value={value}
			className={className.length || removeDefaultStyles ? className : styles['tabs-content']}
		>
			{children}
		</TabsPrimitive.Content>
	);
};

const Trigger = forwardRef<HTMLButtonElement, TabsPrimitive.TabsTriggerProps>(
	({ value, children, onClick = () => null, ...rest }, ref) => {
		return (
			<TabsPrimitive.Trigger
				ref={ref}
				value={value}
				className={styles['tabs-trigger']}
				tabIndex={0}
				onClick={onClick}
				{...rest}
			>
				{children}
			</TabsPrimitive.Trigger>
		);
	}
);

Trigger.displayName = 'Trigger';

const List = ({ children, className = '' }) => {
	const classes = cn(styles['tabs-list'], className);
	return (
		<TabsPrimitive.List className={classes} tabIndex={0}>
			{children}
		</TabsPrimitive.List>
	);
};

const Tabs = ({
	activationMode = 'manual',
	defaultValue,
	value,
	onValueChange,
	children,
	className = '',
	...rest
}: TabsProps) => {
	return (
		<TabsPrimitive.Root
			defaultValue={defaultValue}
			value={value}
			onValueChange={onValueChange}
			activationMode={activationMode}
			className={className}
			{...rest}
		>
			{children}
		</TabsPrimitive.Root>
	);
};

Tabs.List = List;
Tabs.Content = Content;
Tabs.Trigger = Trigger;

export default Tabs;
